import { Link } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/core/Layout";
import ArticleCardV2 from "./ArticleCardV2";
import NewsletterCard from "./NewsletterCard";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import Testimonial from "./Testimonial";
import NewsletterRow from "../components/newsletter/NewsletterRow";
import { Link as ReactScrollLink } from "react-scroll";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import { OutboundLink } from "gatsby-plugin-google-gtag";
require("prismjs/themes/prism-tomorrow.css");

interface NewslettersProps {
  pageContext: {
    newsletters: Array<{
      frontmatter: {
        title: string;
        description: string;
        heroImageUrl: any;
        date: string;
        slug: string;
        tags?: string[];
      };
    }>;
    testimonials: Array<{
      name: string;
      username: string;
      testimonial: string;
      avatarImage: any;
      twitterUrl: string;
    }>;
  };
}

// Declare global gtag function
declare global {
  interface Window {
    gtag: (command: string, action: string, params: any) => void;
  }
}

export default function Newsletters(props: NewslettersProps) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfullySubscribed, setSuccessfullySubscribed] = useState(false);

  function validEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length === 0 || re.test(String(email).toLowerCase());
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (window && !window.location.href.includes("localhost")) {
      window.gtag(`event`, `click`, {
        event_category: `engagement`,
        event_label: "newsletter_subscribe",
      });
    }
    try {
      const currentUrl = window.location.href;
      if (email.length && validEmail(email)) {
        setLoading(true);
        const formData = {
          email: email,
          referringSite: currentUrl,
          firstName: "", // You can add logic to get the first name if needed
          lastName: "", // You can add logic to get the last name if needed
        };
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });
        const result = await response.json();
        console.log("result", result);
        if (
          result &&
          result.data &&
          (result.data.status === "validating" ||
            result.data.status === "active")
        ) {
          setSuccessfullySubscribed(true);
          setTimeout(() => {
            // Handle success (e.g., show a success message)
            setLoading(false);
          }, 1000);
        } else {
          // Handle error (e.g., show an error message)
          setSuccessfullySubscribed(false);
          setLoading(false);
        }
      }
    } catch (error) {
      // Handle error (e.g., show an error message)
      setLoading(false);
      setSuccessfullySubscribed(false);
    }
  };

  const [screenSize, setScreenSize] = React.useState("lg");
  const inlineDispatchLogoClass =
    screenSize === "lg"
      ? "w-36 translate-y-1"
      : screenSize === "md"
      ? "w-32"
      : "w-32";

  // Detect screen size
  React.useEffect(() => {
    const checkScreenSize = () => {
      const width = window.innerWidth;
      if (width >= 1024) {
        setScreenSize("lg");
      } else if (width >= 768) {
        setScreenSize("md");
      } else {
        setScreenSize("sm");
      }
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  // Create structured data for the newsletter collection
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "CollectionPage",
    headline: "JetpackCompose.app's Dispatch Newsletter Archive",
    name: "JetpackCompose.app's Dispatch Newsletter Archive",
    description:
      "A collection of newsletters about Android development and Jetpack Compose, providing insights, tips, and updates for Android developers.",
    url: "https://www.jetpackcompose.app/newsletter",
    publisher: {
      "@type": "Organization",
      name: "JetpackCompose.app",
      logo: {
        "@type": "ImageObject",
        url: "https://www.jetpackcompose.app/logo.png",
      },
    },
    mainEntity: {
      "@type": "ItemList",
      itemListElement: props.pageContext.newsletters.map(
        (newsletter, index) => ({
          "@type": "ListItem",
          position: index + 1,
          url: `https://www.jetpackcompose.app${newsletter.frontmatter.slug}`,
          name: newsletter.frontmatter.title,
          description: newsletter.frontmatter.description,
        })
      ),
    },
    // Add additional schema properties
    dateModified:
      props.pageContext.newsletters[0]?.frontmatter.date ||
      new Date().toISOString(),
    isPartOf: {
      "@type": "WebSite",
      url: "https://www.jetpackcompose.app/",
      name: "JetpackCompose.app",
      description: "The #1 destination for all things Jetpack Compose",
    },
    inLanguage: "en-US",
  };

  return (
    <Layout
      maxWidth="xl"
      pageTitle="JetpackCompose.app's Dispatch: The Android & Jetpack Compose Newsletter for Modern Developers"
      pageDescription="Dispatch makes staying updated with Android Development easy, entertaining and rewarding. Join thousands of Android developers who get smarter about Jetpack Compose every week."
      pageSlug="/newsletter"
      pageImageUrl="/newsletter_poster.png"
      seoImageUrl="/newsletter_poster.png"
      pageType="newsletter_collection"
      articleTags={[
        "Android Newsletter",
        "Jetpack Compose",
        "Android Development",
        "Developer Newsletter",
        "Compose Newsletter",
      ]}
      structuredData={structuredData}
    >
      <div className="mx-6 py-10 lg:py-12 flex flex-col justify-center items-center bg-white rounded-lg">
        <div className="lg:w-3/5 w-full">
          <div className="mb-2 lg:mb-3 flex justify-center items-center">
            <StaticImage
              src="../images/newsletter/dispatch-simple-logo.png"
              height={150}
              alt={"Dispatch Logo"}
              placeholder="blurred"
            />
          </div>
          <h1 className="font-inter text-3xl md:text-4xl lg:text-6xl font-light text-center">
            Get Smart About{" "}
            <span className="font-bold font-inter">Android</span> &{" "}
            <span className="font-bold font-inter">Jetpack Compose</span>
          </h1>

          <div className="font-nunitoSans text-base lg:text-xl my-2 lg:my-3 text-center max-w-3xl mx-auto">
            <span className="font-bold text-lg sm:text-xl lg:text-2xl text-accent">
              5-minute reads. Actionable insights. No fluff.
            </span>
            <p className="mt-1 lg:mt-2 text-base sm:text-base lg:text-lg">
              Keeping up with Android Development is{" "}
              <span className="italic">hard</span>. Dispatch makes it easy,
              entertaining and rewarding with hand-curated content delivered
              straight to your inbox.
            </p>
          </div>

          <div className="mt-2 flex flex-col items-center">
            <div className="flex flex-wrap items-center justify-center gap-2 lg:space-x-4 mb-2">
              <div className="flex items-center">
                <svg
                  className="h-4 w-4 sm:h-5 sm:w-5 text-green-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2 text-xs sm:text-sm md:text-base text-gray-700">
                  Weekly issues
                </span>
              </div>
              <div className="flex items-center">
                <svg
                  className="h-4 w-4 sm:h-5 sm:w-5 text-green-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2 text-xs sm:text-sm md:text-base text-gray-700">
                  Growing community
                </span>
              </div>
              <div className="flex items-center">
                <svg
                  className="h-4 w-4 sm:h-5 sm:w-5 text-green-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-2 text-xs sm:text-sm md:text-base text-gray-700">
                  100% free
                </span>
              </div>
            </div>

            <form
              className="mx-auto flex flex-col w-full max-w-md"
              onSubmit={handleSubmit}
            >
              <div className="flex w-full mb-1 h-11 lg:h-12 overflow-hidden rounded-md shadow-sm ring-1 ring-gray-300">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  autoFocus
                  className="flex-grow w-full h-full rounded-none rounded-l-md bg-white px-3.5 py-0 text-text text-base font-inter placeholder-gray-400 border-0 focus:ring-2 focus:ring-accent focus:border-accent"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="flex-none rounded-none rounded-r-md bg-accent px-5 h-full py-0 text-base font-semibold text-white hover:bg-accent-dark focus:outline-none font-inter transition-colors duration-200 border-0"
                  disabled={loading || successfullySubscribed}
                >
                  {successfullySubscribed
                    ? "SUBSCRIBED!"
                    : loading
                    ? "..."
                    : "GET DISPATCH →"}
                </button>
              </div>
              <p className="text-xs lg:text-sm text-gray-500 text-center">
                Join thousands of Android devs who look forward to Dispatch
                every week. <span className="font-medium">No spam, ever.</span>
              </p>
            </form>
          </div>

          <div className="mt-4 lg:mt-5 text-center text-base sm:text-lg text-gray-700 font-inter">
            Read by Top Android Engineers at
          </div>

          {/* Logo Marquee - Desktop */}
          <div className="relative py-2 lg:py-3 mt-1 lg:mt-1 bg-white mb-2 hidden lg:block">
            {/* Accessibility note for screen readers */}
            <div className="sr-only">
              Our newsletter is read by engineers at companies including Google,
              Airbnb, Meta, Square, Microsoft, Twitter, Reddit, Netflix, and
              TikTok.
            </div>

            <div className="marquee-container">
              <div className="marquee-content">
                {/* First set of logos */}
                <div className="marquee-item">
                  <StaticImage
                    src="../images/google-logo.png"
                    alt="Google"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/airbnb-logo.png"
                    alt="Airbnb"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/meta-logo.png"
                    alt="Meta"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/square-logo.png"
                    alt="Square"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/microsoft-logo.png"
                    alt="Microsoft"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/twitter-logo.png"
                    alt="Twitter"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/reddit-logo.png"
                    alt="Reddit"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/netflix-logo.png"
                    alt="Netflix"
                    placeholder="blurred"
                    width={80}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/tiktok-logo.png"
                    alt="TikTok"
                    placeholder="blurred"
                    width={100}
                  />
                </div>

                {/* Duplicate set for continuous scrolling */}
                <div className="marquee-item">
                  <StaticImage
                    src="../images/google-logo.png"
                    alt="Google"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/airbnb-logo.png"
                    alt="Airbnb"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/meta-logo.png"
                    alt="Meta"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/square-logo.png"
                    alt="Square"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/microsoft-logo.png"
                    alt="Microsoft"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/twitter-logo.png"
                    alt="Twitter"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/reddit-logo.png"
                    alt="Reddit"
                    placeholder="blurred"
                    width={90}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/netflix-logo.png"
                    alt="Netflix"
                    placeholder="blurred"
                    width={80}
                  />
                </div>
                <div className="marquee-item">
                  <StaticImage
                    src="../images/tiktok-logo.png"
                    alt="TikTok"
                    placeholder="blurred"
                    width={100}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile Company Logos */}
          <div className="mb-2 lg:hidden">
            {/* Mobile marquee with optimizations */}
            <div className="relative bg-white rounded-lg py-1 mt-1">
              {/* Accessibility note for screen readers */}
              <div className="sr-only">
                Our newsletter is read by engineers at companies including
                Google, Airbnb, Meta, Square, Microsoft, Twitter, Reddit,
                Netflix, and TikTok.
              </div>

              <div className="marquee-container">
                <div className="marquee-content">
                  {/* First set of logos */}
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/google-logo.png"
                      alt="Google"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/airbnb-logo.png"
                      alt="Airbnb"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/meta-logo.png"
                      alt="Meta"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/square-logo.png"
                      alt="Square"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/microsoft-logo.png"
                      alt="Microsoft"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/twitter-logo.png"
                      alt="Twitter"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/reddit-logo.png"
                      alt="Reddit"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/netflix-logo.png"
                      alt="Netflix"
                      placeholder="blurred"
                      width={60}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/tiktok-logo.png"
                      alt="TikTok"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>

                  {/* Duplicate set for continuous scrolling */}
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/google-logo.png"
                      alt="Google"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/airbnb-logo.png"
                      alt="Airbnb"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/meta-logo.png"
                      alt="Meta"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/square-logo.png"
                      alt="Square"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/microsoft-logo.png"
                      alt="Microsoft"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/twitter-logo.png"
                      alt="Twitter"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/reddit-logo.png"
                      alt="Reddit"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/netflix-logo.png"
                      alt="Netflix"
                      placeholder="blurred"
                      width={60}
                    />
                  </div>
                  <div className="marquee-item">
                    <StaticImage
                      src="../images/tiktok-logo.png"
                      alt="TikTok"
                      placeholder="blurred"
                      width={65}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ReactScrollLink
            to="latestIssues"
            smooth={true}
            duration={1000}
            offset={-70}
            onClick={() => {
              if (window && !window.location.href.includes("localhost")) {
                window.gtag(`event`, `click`, {
                  event_category: `engagement`,
                  event_label: "newsletter_see_latest_issues",
                });
              }
            }}
          >
            <div className="mt-2 lg:mt-3 text-center text-sm lg:text-base text-accent font-inter underline hover:cursor-pointer font-medium hover:text-accent-dark transition-colors duration-200">
              See the latest issues ↓
            </div>
          </ReactScrollLink>
        </div>
      </div>

      {/* Testimonials Section */}
      <div className="px-6 pt-6 pb-24 w-full bg-gray-50">
        <div className="flex justify-center items-center">
          <div className="lg:w-4/5 w-full flex flex-col">
            <h2 className="mt-8 mb-8 font-nunitoSans lg:text-4xl text-2xl text-text text-center">
              What Our Brilliant Subscribers Think{" "}
              <span className="text-gray-500">(We Didn't Pay Them!)</span>
            </h2>

            {/* Featured testimonial */}
            <div className="bg-white p-8 rounded-lg shadow-md max-w-3xl mx-auto mb-12">
              <div className="flex items-start">
                <div className="flex-shrink-0 mr-4">
                  <div className="w-16 h-16 rounded-full overflow-hidden">
                    {props.pageContext.testimonials[0]?.avatarImage && (
                      <GatsbyImage
                        image={
                          getImage(
                            props.pageContext.testimonials[0].avatarImage
                              .childImageSharp.gatsbyImageData
                          ) as IGatsbyImageData
                        }
                        alt={props.pageContext.testimonials[0].name}
                        className="w-full h-full rounded-full"
                      />
                    )}
                  </div>
                </div>
                <div>
                  <div className="text-xl font-semibold text-gray-900">
                    {props.pageContext.testimonials[0]?.name}
                  </div>
                  <div className="text-sm text-gray-600 mb-3">
                    {props.pageContext.testimonials[0]?.username}
                  </div>
                  <div className="text-lg italic text-gray-700 relative">
                    <span className="absolute -left-4 -top-2 text-4xl text-accent opacity-20">
                      "
                    </span>
                    {props.pageContext.testimonials[0]?.testimonial}
                    <span className="absolute -bottom-2 right-0 text-4xl text-accent opacity-20">
                      "
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full lg:columns-3 md:columns-2 columns-1 gap-6 space-y-6">
              {props.pageContext.testimonials
                .slice(1)
                .map((testimonial, index) => {
                  return (
                    <div
                      key={index}
                      className="bg-white p-5 rounded-lg shadow-sm break-inside-avoid"
                    >
                      <Testimonial
                        name={testimonial.name}
                        username={testimonial.username}
                        testimonialText={testimonial.testimonial}
                        userImage={testimonial.avatarImage}
                        userTwitterUrl={testimonial.twitterUrl}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      {/* About the Author Section - Moved here for better conversion */}
      <div className="px-6 py-16 w-full bg-white">
        <div className="flex justify-center items-center">
          <div className="lg:w-4/5 w-full flex flex-col">
            <div className="bg-white border border-gray-200 rounded-xl shadow-md p-6 sm:p-8 max-w-4xl mx-auto">
              <h3 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-gray-900">
                About the Author
              </h3>

              <div className="flex flex-col md:flex-row items-center md:items-start gap-8">
                <div className="w-32 h-32 flex-shrink-0">
                  <StaticImage
                    src="../images/vinay.jpeg"
                    alt="Vinay Gaba - Android Expert & Newsletter Author"
                    className="rounded-full w-full h-full"
                    placeholder="blurred"
                  />
                </div>

                <div className="flex-1">
                  <h4 className="text-xl font-bold text-gray-900 mb-2">
                    Vinay Gaba
                  </h4>
                  <p className="text-accent font-medium mb-4">
                    Google Developer Expert for Android • Tech Lead Manager @
                    Airbnb
                  </p>

                  <p className="text-gray-700 mb-4">
                    I've been writing Android code for roughly ~15 years and
                    have helped build world-class consumer products at companies
                    like Airbnb, Snapchat, and Spotify. I currently lead an
                    ambitious AI-first team at Airbnb that maintains critical
                    pieces of our Product Infrastructure. Over the years, I've
                    given numerous tech talks at conferences around the world,
                    sharing knowledge and best practices with the Android
                    community.
                  </p>

                  <div
                    className="mb-6 relative rounded-lg overflow-hidden shadow-md"
                    onClick={() => {
                      if (
                        window &&
                        !window.location.href.includes("localhost")
                      ) {
                        window.gtag(`event`, `click`, {
                          event_category: `engagement`,
                          event_label: "author_video_play",
                        });
                      }
                    }}
                  >
                    <h5 className="text-sm text-gray-500 mb-2">
                      Watch highlights from my contributions to the Android
                      community
                    </h5>
                    <LiteYouTubeEmbed
                      id="qGBEkhsJb00"
                      title="Vinay Gaba - Android & Jetpack Compose Expert Speaking Highlights"
                      poster="maxresdefault"
                      webp={true}
                      wrapperClass="yt-lite rounded-lg"
                    />
                  </div>

                  <div className="flex flex-wrap gap-3 mb-4">
                    <div>
                      <OutboundLink
                        href="https://www.jetpackcompose.app"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 hover:bg-gray-200 transition-colors duration-200"
                      >
                        <span className="font-medium">Creator of</span>{" "}
                        JetpackCompose.app
                      </OutboundLink>
                    </div>
                    <div>
                      <OutboundLink
                        href="https://github.com/vinaygaba/Learn-Jetpack-Compose-By-Example"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 hover:bg-gray-200 transition-colors duration-200"
                      >
                        <span className="font-medium">Author of</span> Learn
                        Jetpack Compose By Example
                      </OutboundLink>
                    </div>
                    <div>
                      <OutboundLink
                        href="https://github.com/airbnb/Showkase"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 hover:bg-gray-200 transition-colors duration-200"
                      >
                        <span className="font-medium">Author of</span>{" "}
                        airbnb/Showkase
                      </OutboundLink>
                    </div>
                    <div>
                      <OutboundLink
                        href="https://vinaygaba.gumroad.com/l/MakerOS"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm text-gray-700 hover:bg-gray-200 transition-colors duration-200"
                      >
                        <span className="font-medium">Developer of</span> Maker
                        OS
                      </OutboundLink>
                    </div>
                  </div>

                  <p className="text-gray-600 italic">
                    "I created Dispatch not just to cover API changes, but to be
                    the single resource you need in an increasingly noisy
                    Android ecosystem. Each issue maintains an extremely high
                    signal-to-noise ratio, filtering through the chaos so you
                    only get what truly matters for your growth as a developer."
                  </p>

                  <div className="mt-4">
                    <OutboundLink
                      href="https://www.vinaygaba.me/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-accent hover:text-accent-dark font-medium inline-flex items-center transition-colors duration-200"
                    >
                      Learn more about me
                      <svg
                        className="w-4 h-4 ml-1"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </OutboundLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 pt-12 pb-16 w-full bg-white">
        <div className="flex justify-center items-center">
          <div className="lg:w-3/4 md:w-5/6 w-full flex flex-col">
            <h2 className="mt-8 mb-12 font-playfairDisplay lg:text-6xl md:text-5xl text-4xl text-gray-900 text-center">
              What makes Dispatch <span className="text-accent">unique</span>?
            </h2>

            <div className="text-xl text-gray-700 mx-auto mb-12 max-w-3xl text-center">
              <p>
                In a sea of newsletters that merely aggregate links,
                <span className="font-bold text-accent"> Dispatch </span>
                tries to stand out. We deliver concise, valuable insights
                straight to your inbox, so you don't have to sift through
                endless links hoping to find the golden nuggets.
              </p>
              <p className="mt-4">
                Dispatch is about{" "}
                <span className="font-bold">Android developers</span>, not just
                Android development. As our industry evolves with AI and other
                technologies, this newsletter adapts to guide you through these
                transitions—just as it has through platform changes in the past.
              </p>
            </div>

            <div className="grid md:grid-cols-2 gap-8 mb-16">
              {/* Benefit Card 1 */}
              <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px] border border-gray-100">
                <div className="bg-gradient-to-r from-accent to-accent-dark py-4 px-6">
                  <h3 className="text-white font-bold text-xl">
                    Golden Nuggets in 5 Minutes
                  </h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="text-4xl mr-5 mt-1">🧠</div>
                    <div>
                      <p className="text-gray-700 leading-relaxed">
                        Each issue is packed with the most valuable insights and
                        tips, all delivered in a bite-sized format that{" "}
                        <span className="font-semibold">
                          respects your time
                        </span>
                        .
                      </p>
                      <div className="mt-3 text-sm text-gray-500 italic">
                        "I always learn something just by skimming it that makes
                        me want to bookmark the issue now and dig deeper later."
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Benefit Card 2 */}
              <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px] border border-gray-100">
                <div className="bg-gradient-to-r from-accent to-accent-dark py-4 px-6">
                  <h3 className="text-white font-bold text-xl">
                    Entertaining and Insightful
                  </h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="text-4xl mr-5 mt-1">😄</div>
                    <div>
                      <p className="text-gray-700 leading-relaxed">
                        Learning should be fun. Dispatch is not just informative
                        but also{" "}
                        <span className="font-semibold">
                          engaging and humorous
                        </span>
                        , making it a delight to read.
                      </p>
                      <div className="mt-3 text-sm text-gray-500 italic">
                        "The content is light, fun, and still useful. I
                        especially appreciate the small tips in each issue."
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Benefit Card 3 */}
              <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px] border border-gray-100">
                <div className="bg-gradient-to-r from-accent to-accent-dark py-4 px-6">
                  <h3 className="text-white font-bold text-xl">
                    Curated for Quality
                  </h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="text-4xl mr-5 mt-1">📋</div>
                    <div>
                      <p className="text-gray-700 leading-relaxed">
                        No more endless link lists. Each issue is{" "}
                        <span className="font-semibold">
                          painstakingly curated
                        </span>{" "}
                        to ensure you get only the most relevant bits. We do the
                        hard work so you can focus on what matters.
                      </p>
                      <div className="mt-3 text-sm text-gray-500 italic">
                        "The Dev Delight and Featured Dev sections are
                        particular favorites of mine!"
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Benefit Card 4 */}
              <div className="bg-white rounded-xl shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px] border border-gray-100">
                <div className="bg-gradient-to-r from-accent to-accent-dark py-4 px-6">
                  <h3 className="text-white font-bold text-xl">
                    Absolutely Free
                  </h3>
                </div>
                <div className="p-6">
                  <div className="flex items-start">
                    <div className="text-4xl mr-5 mt-1">🎁</div>
                    <div>
                      <p className="text-gray-700 leading-relaxed">
                        No cost to readers. Dispatch is{" "}
                        <span className="font-semibold">completely free</span>,
                        ensuring that top-notch content is accessible to
                        everyone in the Android community.
                      </p>
                      <div className="mt-3 text-sm text-gray-500 italic">
                        "I truly love this newsletter ❤️ Spot on content and I
                        know there's a lot of effort that goes behind it."
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Secondary CTA - Redesigned */}
            <div className="bg-indigo-50 p-5 sm:p-8 lg:p-10 rounded-xl shadow-md max-w-3xl mx-auto mb-12 border border-indigo-100">
              <h3 className="text-xl sm:text-2xl font-bold text-center mb-4 text-gray-900">
                Ready to get smarter about Android & Jetpack Compose?
              </h3>
              <form
                className="mx-auto flex flex-col sm:flex-row max-w-md mb-4 secondary-cta-form"
                onSubmit={handleSubmit}
              >
                <div className="flex-grow sm:flex-1">
                  <input
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full rounded-md sm:rounded-r-none sm:rounded-l-md bg-white px-4 py-0 h-12 text-text text-base border-0 shadow-sm mb-2 sm:mb-0 focus:outline-none"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="flex-none rounded-md sm:rounded-l-none sm:rounded-r-md bg-accent px-5 py-0 h-12 text-base font-semibold text-white border-0 shadow-sm hover:bg-accent-dark focus:outline-none transition-colors duration-200"
                  disabled={loading || successfullySubscribed}
                >
                  {successfullySubscribed
                    ? "SUBSCRIBED!"
                    : loading
                    ? "SUBSCRIBING..."
                    : "SUBSCRIBE NOW"}
                </button>
              </form>
              <p className="text-center text-sm sm:text-base text-gray-700">
                Join the community of Android developers who look forward to
                Dispatch every week.
              </p>
            </div>

            {/* Featured Quote - Enhanced design */}
            <div className="relative max-w-4xl mx-auto mt-12 mb-8 py-12 px-6 sm:px-12 bg-slate-800 rounded-xl shadow-lg text-white overflow-hidden">
              {/* Quote marks background */}
              <div className="absolute top-0 left-0 text-white opacity-5">
                <svg
                  className="w-40 h-40"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M14.017 21v-7.391c0-5.704 3.731-9.57 8.983-10.609l.995 2.151c-2.432.917-3.995 3.638-3.995 5.849h4v10h-9.983zm-14.017 0v-7.391c0-5.704 3.748-9.57 9-10.609l.996 2.151c-2.433.917-3.996 3.638-3.996 5.849h3.983v10h-9.983z" />
                </svg>
              </div>
              <p className="relative z-10 text-2xl sm:text-3xl font-playfairDisplay italic text-center leading-relaxed">
                We filter through the noise so you don't have to. Five minutes
                with Dispatch saves you hours of searching for what really
                matters in Android Development.
              </p>

              {/* Coffee cup icon */}
              <div className="absolute bottom-0 right-0 text-white opacity-5 transform translate-y-1/4 translate-x-1/4">
                <svg
                  className="w-40 h-40"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.89 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-6 mt-6 mb-24">
        <div className="flex justify-center items-center">
          <div className="lg:w-4/5 w-full flex flex-col">
            <h2
              id="latestIssues"
              className="mb-12 font-nunitoSans lg:text-4xl md:text-4xl text-2xl text-text text-center"
            >
              <span className="border-b-4 border-accent pb-2">
                Latest Issues
              </span>
            </h2>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6">
              {props.pageContext.newsletters.map((newsletter, index) => {
                return (
                  <Link
                    key={index}
                    to={newsletter.frontmatter.slug}
                    className="transform transition-transform duration-300 hover:-translate-y-2 hover:shadow-xl rounded-lg overflow-hidden"
                    partiallyActive={true}
                    onClick={() => {
                      if (
                        window &&
                        !window.location.href.includes("localhost")
                      ) {
                        window.gtag(`event`, `click`, {
                          event_category: `engagement`,
                          event_label: newsletter.frontmatter.slug,
                        });
                      }
                    }}
                  >
                    <NewsletterCard
                      title={newsletter.frontmatter.title}
                      description={newsletter.frontmatter.description}
                      dynamicImage={newsletter.frontmatter.heroImageUrl}
                      date={newsletter.frontmatter.date}
                      tags={newsletter.frontmatter.tags || ["General"]}
                    />
                  </Link>
                );
              })}
            </div>

            {/* Final CTA Section */}
            <div className="mt-20 bg-indigo-600 rounded-xl shadow-lg p-5 sm:p-8 lg:p-10 text-white text-center">
              <h3 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6">
                Don't Miss Another Issue!
              </h3>
              <p className="text-lg sm:text-xl mb-6 sm:mb-8 max-w-2xl mx-auto">
                Stay ahead of the curve. Get actionable Android & Jetpack
                Compose insights delivered straight to your inbox.
              </p>

              <form
                className="mx-auto flex flex-col sm:flex-row max-w-md mb-4 final-cta-form"
                onSubmit={handleSubmit}
              >
                <div className="flex-grow sm:flex-1">
                  <input
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full rounded-md sm:rounded-r-none sm:rounded-l-md bg-white px-4 py-0 h-12 text-text text-base border-0 shadow-sm mb-2 sm:mb-0 focus:outline-none"
                    placeholder="Your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <button
                  type="submit"
                  className="flex-none rounded-md sm:rounded-l-none sm:rounded-r-md bg-white px-5 py-0 h-12 text-base font-semibold text-indigo-600 border-0 shadow-sm hover:bg-gray-100 focus:outline-none transition-colors duration-200"
                  disabled={loading || successfullySubscribed}
                >
                  {successfullySubscribed
                    ? "SUBSCRIBED!"
                    : loading
                    ? "SUBSCRIBING..."
                    : "JOIN NOW →"}
                </button>
              </form>
              <p className="text-white/80 text-sm">
                Join a passionate community of Android developers. Free forever.
                No spam.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
