import React from "react";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import { format } from "date-fns";

interface NewsletterCardProps {
  title: string;
  dynamicImage?: any;
  description: string;
  date?: string;
  tags?: string[];
}

export default function NewsletterCard(props: NewsletterCardProps) {
  const dynamicImage: IGatsbyImageData | undefined = getImage(
    props.dynamicImage?.childImageSharp?.gatsbyImageData
  );
  const formattedDate = props.date
    ? format(new Date(props.date), "MMMM dd, yyyy")
    : null;

  return (
    <div className="rounded-xl overflow-hidden border border-gray-200 shadow-sm transition-all duration-300 hover:shadow-xl bg-white h-full flex flex-col">
      <div className="relative">
        <GatsbyImage
          image={dynamicImage as IGatsbyImageData}
          className="w-full object-cover aspect-video"
          alt={`Dispatch newsletter: ${props.title}`}
        />
      </div>
      <div className="px-6 py-4 font-inter font-normal flex-grow">
        <div className="flex items-center text-sm mb-2 text-slate-600">
          <svg className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
              clipRule="evenodd"
            />
          </svg>
          {formattedDate}
        </div>
        <h3 className="text-xl sm:text-2xl font-semibold mb-2 text-gray-900 break-words leading-tight">
          {props.title}
        </h3>
        <p className="text-gray-700 text-base mb-4">{props.description}</p>

        {props.tags && props.tags.length > 0 && (
          <div className="mt-auto">
            {props.tags.map((tag, index) => (
              <span
                key={index}
                className="inline-block bg-slate-800 rounded-full px-3 py-1 text-sm font-medium text-white mr-2 mb-2"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="px-6 pb-4 pt-2 mt-auto border-t border-gray-100">
        <div className="flex items-center text-accent font-medium text-sm">
          <span>Read issue</span>
          <svg
            className="w-4 h-4 ml-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M14 5l7 7m0 0l-7 7m7-7H3"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
